import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { currency } from '../../../../utils/format/currency';
import StyledPropertySold from '../StyledPropertySold';
import { CarouselButtonRightWithBackground } from '../../button/CarouselButtonRightWithBackground';
import { CarouselButtonLeftWithBackground } from '../../button/CarouselButtonLeftWithBackground';
import { ImageCarouselBulletsWithAnimation } from '../../carousel/ImageCarouselBulletsWithAnimation';
import { Estate, Prices } from '../../../../__types__/generated/cluster';
import Image from '../../../containers/search/card/image/Index';
import {
  CheckListType,
  checklistIsChecked
} from '../../../containers/search/util/checklistIsChecked';
import { isNewDevelopment } from '../../../containers/search/util/isNewDevelopment';
import { Area } from './Area';
import { Subunits } from './Subunits';

interface ITab {
  key?: string;
  item: Partial<Estate>;
  currentPage: number;
  className?: string;
}

interface IPrices {
  prices?: Partial<Prices>;
}

interface IImage {
  id: string;
  loaded: boolean;
  src?: string;
}

const PriceInfo: React.FC<IPrices> = ({ prices }) => {
  if (!prices) return <>-</>;
  const { askingPrice, askingPriceTo } = prices;
  if (!askingPrice) return <>-</>;
  if (askingPriceTo && askingPrice != askingPriceTo) {
    return (
      <>
        {currency({
          number: askingPrice
        })}{' '}
        - {currency({ number: askingPriceTo })},-
      </>
    );
  }
  return <>{currency({ number: askingPrice })},-</>;
};

export const PropertyCard: React.FC<ITab> = React.memo(
  ({ item, currentPage, className }) => {
    const [loaded, setLoaded] = React.useState(false);
    const [missingImage, setMissingImage] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [prevIndex, setPrevIndex] = React.useState(-1);
    const [currentDirection, setCurrentDirection] = React.useState<
      null | string
    >(null);
    const [images, setImages] = React.useState<IImage[]>(
      item?.images?.list?.map<IImage>((e, index) => {
        return { id: e?.imageId ?? `img${index}`, src: e?.url, loaded: false };
      }) ?? []
    );

    React.useEffect(() => {
      if (loaded) {
        setLoaded(false);
      }
    }, [currentPage]);

    React.useEffect(() => {
      setMissingImage(!images?.length ? true : false);
    }, [item, currentPage]);

    const prev = () => {
      const length = item?.images?.list?.length ?? 0;
      if (!length) return;
      const idx = currentIndex - 1;
      setPrevIndex(currentIndex);
      setCurrentIndex(idx < 0 ? length - 1 : idx);
      setCurrentDirection('prev');
    };
    const next = () => {
      const length = item?.images?.list?.length ?? 0;
      if (!length) return;
      setCurrentDirection('next');
      const idx = currentIndex + 1;
      setPrevIndex(currentIndex);
      setCurrentIndex(idx < length ? idx : 0);
    };

    return (
      <StyledPropertyItem
        className="property-item"
        onClick={() => {
          if (typeof window !== 'undefined' && item?.assignmentNumber) {
            window.location.href = `https://privatmegleren.no/${item.assignmentNumber}`;
          }
        }}
      >
        <StyledPropertyImageWrapper>
          {images?.length ? (
            <StyledPropertyImage loaded={loaded}>
              <CarouselButtonLeftWithBackground
                id={item?.estateId}
                className="left"
                onClick={(e) => {
                  e.stopPropagation();
                  prev();
                }}
              />
              <LazyLoad height={250} throttle={100} offset={50}>
                {prevIndex > -1 &&
                  prevIndex != currentIndex &&
                  images?.map((img, index) => {
                    if (index !== prevIndex || !img) return null;
                    return (
                      <Image
                        key={`img${index}`}
                        className="image"
                        src={img?.src}
                        loaded={img?.loaded}
                      />
                    );
                  })}
                {images?.map((img, index: any) => {
                  if (index !== currentIndex && img?.src) return null;
                  return (
                    <Image
                      key={`img${index}`}
                      src={img?.src}
                      loaded={img?.loaded}
                      className="image"
                      classNameIcon={prevIndex !== -1 ? 'bkg' : undefined}
                      showIcon={true}
                      onLoad={() => {
                        if (img?.loaded) return;
                        setImages((prev) => {
                          return prev.map((item, imageIndex) => {
                            if (index !== imageIndex) return item;
                            return { ...item, loaded: true };
                          });
                        });
                      }}
                    />
                  );
                })}
              </LazyLoad>
              <ImageCarouselBulletsWithAnimation
                className="bullets"
                images={item?.images?.list ?? []}
                currentIndex={currentIndex}
                currentDirection={currentDirection}
              />
              <CarouselButtonRightWithBackground
                id={item?.estateId}
                className="right"
                onClick={(e) => {
                  e.stopPropagation();
                  next();
                }}
              />
            </StyledPropertyImage>
          ) : null}

          {missingImage ? <Image showIcon={true} /> : null}
          {isNewDevelopment(item?.assignmentTypeMapped) && !item.sold ? (
            <StyledPropertyNybygg />
          ) : null}
          {item?.sold ? <StyledPropertySold date={item?.soldAt} /> : null}
          {isNewDevelopment(item?.assignmentTypeMapped) &&
          checklistIsChecked({
            type: CheckListType.Teaser,
            list: item?.checklist
          }) &&
          !item.published ? (
            <Subunits sold={0} total={0} available={0} teaser={true} />
          ) : null}
          {isNewDevelopment(item?.assignmentTypeMapped) &&
          !checklistIsChecked({
            type: CheckListType.Teaser,
            list: item?.checklist
          }) &&
          !item?.sold &&
          item?.projectStatistics ? (
            <Subunits
              className={missingImage ? `` : `bullet`}
              sold={item?.projectStatistics?.sold ?? 0}
              total={item?.projectStatistics?.total ?? 0}
              available={item?.projectStatistics?.available ?? 0}
            />
          ) : null}
        </StyledPropertyImageWrapper>
        <StyledPropertyInfo className={className}>
          <StyledPropertyLocation className="county">
            {item?.localArea ?? item?.municipalityArea}
            <br />
            {item?.countyName}
          </StyledPropertyLocation>
          <StyledPropertyData>
            <span>{item?.streetAddress}</span>
            <span>
              {item?.type}{' '}
              {!isNewDevelopment(item?.assignmentTypeMapped) && item?.size ? (
                <>
                  - <Area size={item.size} plot={item.plot?.size} />
                </>
              ) : null}
            </span>
            {isNewDevelopment(item?.assignmentTypeMapped) && item?.size ? (
              <Area size={item.size} />
            ) : null}
          </StyledPropertyData>
          <StyledPropertyPrice className="price">
            <PriceInfo prices={item?.prices} />
          </StyledPropertyPrice>
        </StyledPropertyInfo>
      </StyledPropertyItem>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps?.item?.estateId === nextProps?.item?.estateId) {
      return true;
    }
    return false;
  }
);

const StyledPropertyImageWrapper: any = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: rgb(15, 15, 15);
  &:hover {
    .left,
    .right {
      display: block;
    }
  }
  .left,
  .right {
    display: none;
  }
  .image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &.lazy-image {
        position: absolute;
        width: 1px;
        height: 1px;
        z-index: -1;
      }
    }
  }
  .pm-logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    path {
      fill: rgb(232, 200, 147);
    }
  }
`;

const StyledPropertyImage: any = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: relative;
  width: 100%;
  height: 250px;
`;

const StyledPropertyNybygg = styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: 70px;
  height: 70px;
  background: url('https://cdn.reeltime.no/pm_assets/img/nyeboliger.jpg')
    no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

const StyledPropertyInfo = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 1.5em;
`;

const StyledPropertyData = styled.div`
  margin: 10px 0 0 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  line-height: 1.6;
  color: white;
`;

const StyledPropertyLocation = styled.div`
  transition: color 0.2s ease-in-out;
  display: block;
  font-size: 1em;
  line-height: 1.1em;
  color: #e8c893;
`;

const StyledPropertyPrice = styled.div`
  transition: color 0.2s ease-in-out;
  margin-top: 10px;
  display: block;
  font-size: 1em;
  color: #e8c893;
`;

const StyledPropertyItem = styled.div`
  transition: background 0.2s ease-in-out;
  display: flex;
  grid-column: span 1;
  flex-flow: column;
  background: #1c1c1c;
  overflow: hidden;
  .county {
    word-break: break-all;
  }
  &:hover {
    cursor: pointer;
    background: #e5c88f;
    .county {
      color: black;
    }
    .price {
      color: black;
    }
  }
`;

export const PropertyListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  &::after {
    content: '';
    flex: auto;
  }
  .property-item {
    width: calc(33.3333% - 10px);
    &:nth-last-child(1) {
      margin-left: 15px;
    }
  }
  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(100% / 3 - 10px), calc(100% / 3 - 10px))
    );
    grid-gap: 15px;
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
  }
  @media screen and (max-width: 580px) {
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  @media screen and (min-width: 580px) and (max-width: 960px) {
    .property-item {
      width: calc(50% - 10px);
      &:nth-last-child(1) {
        margin-left: 15px;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc(100% / 2 - 10px), calc(100% / 2 - 10px))
      );
      .property-item {
        width: 100%;
        &:nth-of-type(even) {
          margin-left: 0;
        }
        &:nth-last-child(1) {
          margin-left: auto;
        }
      }
    }
  }
`;
