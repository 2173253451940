import * as React from 'react';
import { useEventListener } from './useEventListener';

type Behavior = 'smooth' | 'auto';

interface IArgs {
  ref?: any;
  distanceTop: number;
  behaviour: Behavior;
}

export const useScrollToElement = () => {
  if (typeof window !== 'undefined') {
    const [currentScroll, setCurrentScroll] = React.useState(0);

    const handler = () => {
      setCurrentScroll(window.pageYOffset);
    };

    useEventListener('scroll', handler);

    const scrollTo = (args: IArgs) => {
      if (args?.ref?.current) {
        let pos = args.ref.current.getBoundingClientRect();
        window.scroll({
          top: pos.y + currentScroll - args.distanceTop,
          behavior: args.behaviour ? args.behaviour : 'auto'
        });
      }
    };
    return { scrollTo };
  }
  return { scrollTo: null };
};
